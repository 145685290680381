@import "@styles/_variables.scss";
.gallery {
  width: 100%;
  overflow: hidden;
  // max-width: 1216px;
  // height: 647px;

  & > .image {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: $tablet-breakpoint) {
      height: 300px;
    }

    &:hover {
      & > .next {
        display: flex;
        cursor: url("/images/Right.png"), auto;

        @media screen and (max-width: $tablet-breakpoint) {
          display: none;
        }
      }

      & > .prev {
        display: flex;
        cursor: url("/images/Left.png"), auto;

        @media screen and (max-width: $tablet-breakpoint) {
          display: none;
        }
      }
    }

    & > .next {
      width: 50%;
      position: absolute;
      right: 0;
      height: 100%;
      display: none;
      z-index: 99;

      @media screen and (max-width: $tablet-breakpoint) {
        display: none;
      }

      &.full {
        width: 100%;
      }
    }

    & > .prev {
      width: 50%;
      position: absolute;
      left: 0;
      height: 100%;
      display: none;
      z-index: 99;

      @media screen and (max-width: $tablet-breakpoint) {
        display: none;
      }

      &.full {
        width: 100%;
      }
    }

    // &:hover {
    //   cursor: url(images/next.svg), auto;

    //   & > .button-arrow {
    //     opacity: 0;
    //   }
    // }

    & > .button-arrow {
      position: absolute;
      top: calc((100% - 80px) / 2);
      right: 0px;
      z-index: 9;
      transition: opacity 500ms;

      @media screen and (max-width: $tablet-breakpoint) {
        display: none;
      }

      &.left {
        left: 0px;
        right: initial;
      }
    }

    & > div {
      position: absolute;
      height: 100%;
      width: 100%;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    & > .counter {
      pointer-events: none;
      position: absolute;
      left: 33px;
      bottom: 33px;
      color: #fff;
      z-index: 2;

      @media screen and (max-width: $tablet-breakpoint) {
        &.hide-mobile {
          display: none;
        }
      }

      & > span {
        margin-top: 10px;
      }
    }
  }

  & > .marker {
    flex-direction: row;
    justify-content: center;
    margin-top: 27px;

    & > button {
      height: 20px;
      width: 83px;
      border-bottom: 1px solid rgba(32, 32, 32, 0.2);
      background-color: transparent;
      cursor: pointer;

      &.selected {
        border-bottom: 1px solid #a79669;
      }

      & + button {
        margin-left: 20px;
      }
    }
  }
}
