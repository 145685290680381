@import "@styles/_variables.scss";
.carrousel-full {
  padding: 79px Max(113px, calc((100% - 1440px) / 2 + 113px)) 113px;
  background-color: #f9f6ef;
  overflow: hidden;
  & > h1 {
    font-size: 13px;
  }
  &.white {
    background-color: #fff;
  }

  &.no-margin {
    & > .row {
      margin-bottom: 0;
    }
  }

  &.long-title {
    & > .row {
      margin-bottom: 0;
      h2 {
        max-width: 799px;
        width: 100%;
      }
    }
  }

  &.pool-bar {
    & > .row {
      @media screen and (max-width: 1020px) {
        margin-bottom: 22px;
      }
      & > div {
        @media screen and (max-width: 1020px) {
          display: none !important;
        }
      }
    }

    & > .duplicate {
      @media screen and (max-width: 1020px) {
        display: flex !important;
        margin-top: 22px;
      }
    }
  }

  &.wider {
    & > .row {
      & > .ab-paragraph-text {
        & > p {
          max-width: 584px;
        }
      }
    }
  }

  &.check-image {
    & > .row {
      & > .ab-paragraph-text {
        & > .inner-list {
          & > ul {
            & > li {
              display: flex;
              flex-direction: row;
              margin-bottom: 5px;
              &:before {
                content: url(/images/check.svg);
                height: 5px;
                width: 5px;
                margin-right: 15px;
              }
            }
            max-width: 584px;
          }
        }
      }
    }
    & > .links {
      margin-top: 25px;
      margin-right: 29%;

      @media screen and (max-width: 1020px) {
        justify-content: flex-start;
        margin-right: 10px;
        margin-top: 25px;
      }
    }
  }

  &.descubra {
    & > .row {
      h2 {
        font-family: Silk;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        /* or 125% */

        letter-spacing: -0.04em;
      }

      & > .ab-paragraph-text {
        & > p {
          &:nth-of-type(1) {
            font-family: GT Eesti;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            /* identical to box height, or 160% */

            letter-spacing: 0.2em;
            text-transform: uppercase;

            /* BROWN_ABADÍA */

            color: #372f2f;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 61px 30px 50px;
  }
  & > .row {
    flex-direction: row;
    order: 0;
    margin-bottom: 80px;
    @media screen and (max-width: 1020px) {
      flex-direction: column;
      margin-bottom: 32px;
    }

    &.no-margin {
      margin-bottom: 0;
    }

    h2 {
      color: #372f2f;
      width: 100%;
      max-width: 381px;
      margin-right: 36px;

      &.long {
        max-width: 590px;
      }

      @media screen and (max-width: 1020px) {
        margin-bottom: 11px;
        width: 100%;
      }
    }
  }

  & > .links {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 11px;
    margin-bottom: 22px;
    order: 1;

    &.after {
      order: 3;
      margin-top: 30px;
    }

    @media screen and (max-width: $tablet-breakpoint) {
      order: 3;
      margin-bottom: initial;
      margin-top: 42px;
      justify-content: center;
    }

    & > .ab-link {
      & + .ab-link {
        margin-left: 22px;
      }
    }
  }

  & > .gallery {
    align-self: center;
    order: 2;
  }

  & > iframe {
    width: 100%;
    height: 600px;

    @media screen and (max-width: $tablet-breakpoint) {
      height: 317px;
    }
  }

  & > .duplicate {
    display: none !important;
    order: 3;
  }
}
